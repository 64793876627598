import React from 'react'
import AppWrapper from '../app/layouts/AppWrapper'
import SignUpPage from '../ui/pages/SignUpPage'
import HelmetComponent from '../ui/layouts/HelmetComponent'
import metaTags from '../config/meta-tags/en.json'
import { Formik } from 'formik'
import {
  schema,
  handleSubmit,
  initialValues,
} from '../integrations/enter-platform-short.js'

const SignUp = props => (
  <>
    <HelmetComponent
      title={metaTags.signUpTitle}
      description={metaTags.signUp}
      page="sign-up"
    />
    <AppWrapper {...props}>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit('Request page')}
        validationSchema={schema}
      >
        {formikProps => <SignUpPage formik={formikProps} />}
      </Formik>
    </AppWrapper>
  </>
)

export default SignUp
